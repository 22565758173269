<template>
	<div class="mt-3 jobs-location-results-list">
		<div v-if="loadingStatus" class="loading-container">
			<div class="loading"></div>
			Daten werden geladen...
		</div>
		<ul v-if="results.length > 0" class="list-unstyled">
			<li v-for="(item, i) in results" :key="`${i}-${item.stellenangebotId}`">
				<font-awesome-icon :icon="['fal', 'arrow-circle-right']" />
				<nuxt-link
					:to="
						localePath({
							name: 'job-finden-job-stellenangebotId-jobTitel',
							params: {
								stellenangebotId: item.stellenangebotId,
								jobTitel: item.jobTitel
									.replace(/\s+|[,\/]/g, '-')
									.toLowerCase(),
							},
						})
					"
				>
					{{ item.jobTitel }}
				</nuxt-link>
			</li>
		</ul>
		<div v-else>
			Zur Zeit sind keine Jobs für verfügbar.
		</div>
	</div>
</template>


<script>
import { mapState } from 'vuex'
import JobsListMixins from '../mixins/JobsListMixins'

export default {
	mixins: [JobsListMixins],
	props: ['item'],
	data() {
		return {
			stellenangebotId: this.$route.params.stellenangebotId,
			Agreement: 'nach Vereinbarung',
			Button: 'Merken',
		}
	},
	computed: {
		...mapState(['results', 'datajobs']),
		rows() {
			return this.results.length
		},
		results: {
			get() {
				return this.$store.state.results
			},
		},
		loadingStatus () {
			return this.$store.getters.loadingStatus
		},
	},
	mounted() {
		this.$store.dispatch('executeSearchSubsidiary')
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
ul.jobs-location-results-list {
	list-style: none;
	padding: 0;

	li {
		margin-bottom: unit(5);
		@include responsive-type(2, 1.2);
		display: flex;

		.svg-inline--fa {
			position: relative;
			top: 1px;
			margin-right: rem(10);
			color: $secondary;
		}
	}
}
</style>
