<template>
	<b-row>
		<!-- <b-col xl="3 mb-3" md="6">
			<b-input-group append="Ergebnisse/Seite">
				<b-form-select
					v-model="resultsPerPage"
					:options="perPageOptions"
					class="w-25"
				>
					<template v-slot:first>
						<option :value="null" disabled>-- Results Per Page --</option>
					</template>
				</b-form-select>
			</b-input-group>
		</b-col>

		<b-col xl="3 mb-3" md="6">
			<b-input-group append="Sortierung">
				<b-form-select
					v-model="orderBy"
					:options="orderByOptions"
					class="w-25"
				>
					<template v-slot:first>
						<option :value="null" disabled>-- Neueste zuerst --</option>
					</template>
				</b-form-select>
			</b-input-group>
		</b-col> -->

		<b-col
			xxl="6 mb-3"
			class="d-flex justify-content-end"
		>
			<b-pagination
				v-model="currentPage"
				:total-rows="resultsCount"
				:per-page="resultsPerPage"
				aria-controls="results-group"
				first-number
       			last-number
			>

			</b-pagination>
		</b-col>

	</b-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			perPageOptions: [
				{ text: '10', value: 10, disabled: false },
				{ text: '50', value: 50, disabled: false },
				{ text: '100', value: 100, disabled: false },
			]
		};
	},
	computed: {
		...mapState([
			'resultsCount',
		]),
		currentPage: {
			get() {
				return this.$store.state.currentPage;
			},
			set(value) {
				this.$store.dispatch('setCurrentPage', value);
			},
		},
		resultsPerPage: {
			get() {
				return this.$store.state.resultsPerPage;
			},
			set(value) {
				this.$store.dispatch('setResultsPerPage', value);
			},
		},
		orderBy: {
			get() {
				return this.$store.state.orderBy;
			},
			set(value) {
				this.$store.dispatch('setOrderBy', value);
			},
		},
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/common';
$custom-select--border-radius: 50rem!default;

	.custom-select {
		border-color: $border-color;
		border-top-left-radius: $custom-select--border-radius;
		border-bottom-left-radius: $custom-select--border-radius;
		font-size: rem(15);
		color: $body-color;
		padding: 0 10px;
	}
	.input-group-text {
		border-radius: 0;
		border-top-right-radius: $custom-select--border-radius;
		border-bottom-right-radius: $custom-select--border-radius;
		border-color: $border-color;
		font-size: rem(15);
	}

	.results-top {
		.pagination {
			display: none;
		}
	}

	.pagination {
		margin-bottom: 30px;
	}


	ul.pagination li:nth-child(6n+1)  {
		display: none;
	}

</style>
