<template>
	<div>
		<Zipcode @selected="zipcodeSelected" country="DE">
			<input
				ref="autocomplete"
				lazy
				v-model="searchString"
				class="form-control"
				placeholder="PLZ oder Ort"
				id="search_string"
				autocomplete="off"
			/>
		</Zipcode>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Zipcode from '~/components/atoms/forms/select/Zipcode.vue'
export default {
	components: {
		Zipcode,
	},
	computed: {
		...mapState(['searchStringDistance', 'searchString']),
		searchString: {
			get() {
				return this.$store.state.searchString
			},
			set(value) {
				this.$store.dispatch('setSearchString', value)
			},
		},
		searchStringDistance: {
			get() {
				return this.$store.state.searchStringDistance
			},
			set(value) {
				this.$store.dispatch('setSearchStringDistance', value)
			},
		},
	},
	methods: {
		executeSearch() {
			this.$store.dispatch('setSearchString', this.searchString)
			this.$store.dispatch(
				'setSearchStringDistance',
				this.searchStringDistance
			)
		},

		resetSearchString() {
			this.$store.dispatch('setSearchString')
			this.$store.dispatch('setSearchStringDistance')
		},
		handleInputEnter(event) {
			this.$store.dispatch('setSearchString', event.target.value)
		},
		zipcodeSelected(value) {
			// searching for "Deutschland" could make problems, so check first if there are entries
			let zipOrNameOfCity =
				typeof value.place.address_components[0] !== 'undefined'
					? value.place.address_components[0].long_name
					: ''
			let nameOfCity =
				typeof value.place.address_components[1] !== 'undefined'
					? value.place.address_components[1].long_name
					: ''
			let search_string = zipOrNameOfCity

			// zipOrNameOfCity is a number => it is a zip code, so show the name of the city, too
			if (zipOrNameOfCity.match(/^\d+$/)) {
				search_string = search_string + ' ' + nameOfCity
			}

			this.$store.dispatch('setSearchString', search_string)
			this.$store.dispatch('setSearchStringDistance', value.latlng)
		},
	},
	mounted() {
		const input = document.getElementById('search_string')

		enableEnterKey(input)

		function enableEnterKey(input) {
			// Store original event listener
			const _addEventListener = input.addEventListener

			const addEventListenerWrapper = (type, listener) => {
				if (type === 'keydown') {
					// Store existing listener function
					const _listener = listener
					listener = (event) => {
						// Simulate a 'down arrow' keypress if no address has been selected
						const suggestionSelected = document.getElementsByClassName(
							'pac-item-selected'
						).length
						if (event.key === 'Enter' && !suggestionSelected) {
							const e = new KeyboardEvent('keydown', {
								key: 'ArrowDown',
								code: 'ArrowDown',
								keyCode: 40,
							})
							_listener.apply(input, [e])
						}
						_listener.apply(input, [event])
					}
				}
				_addEventListener.apply(input, [type, listener])
			}

			input.addEventListener = addEventListenerWrapper
		}
	},
	watch: {
		searchString() {
			if (this.searchString === '') {
				this.$store.dispatch('clearFilters')
			}
		},
	},
}
</script>
