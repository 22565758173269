// INFO With this method add search item to bookmarklist
export default {
	methods: {

		isBookmark(stellenangebotId) {
			const index = this.tempbookmark.findIndex(item => item.stellenangebotId == stellenangebotId);
			return index > -1;
		},

		toggleBookmark(event, jobTitel, jobOrt, jobRegion, jobOrte, stellenangebotId) {

			let element = event.target;
			element = element.closest('.bookmarks');
			element.classList.toggle('on');

			if (element.classList.contains('on')) {

				// add to bookmark
				let item = {
					jobTitel,
					jobOrt,
					jobRegion,
					jobOrte,
					stellenangebotId
				};
				this.tempbookmark.push(item)
				this.$store.commit('addToBookmark', { ...item })
			}
		}
	}
};
