<template>
	<div class="jobs-location-results">
		<div class="row gy-6 gx-6">
			<div class="col-md-12 col-lg-7">
				<h2 v-html="header"></h2>

				<input
					@click="handleInputClick"
					@click.enter.prevent="handleInputEnter"
					lazy
					v-model="listLocation"
					ref="listLocation"
					type="hidden"
				/>

				<ResultItemJobLocation />
			</div>
			<div class="col-md-12 col-lg-5">
				<h2
					class="jobs-location-results-header-light"
					v-html="subheader"
				></h2>
				<html-parser :content="searchText" />
				<nav-link
					to="/bewerber/jobsuche/initiativbewerbung"
					class="mt-4 btn btn-secondary"
					id="button-bewerben-initiativbewerbung"
				>
					Jetzt initiativ bewerben
				</nav-link>
			</div>
		</div>
	</div>
</template>

<script>
import ResultItemJobLocation from '../jobmarket/results/ResultItemJobLocation'
import SearchResults from '../jobmarket/results/SearchResults'
import CeHeaderBase from '~typo3/components/content/elements/CeHeader.vue'
import MediaGallery from '../../atoms/media/Gallery.vue'

export default {
	name: 'jobsearchcategory',
	extends: CeHeaderBase,
	components: {
		ResultItemJobLocation,
		SearchResults,
		MediaGallery,
	},
	props: {
		searchText: {
			type: String,
			required: true,
			default: '',
		},
		InitiativeApplicationLink: {
			type: String,
			default: '',
		},
		listLocation: {
			type: String,
			required: true,
			default: '',
		},
	},
	mounted() {
		this.$refs.listLocation.click()
	},
	computed: {
		locationStringSubsidary: {
			get() {
				return this.listLocation
			},
			set() {
				this.listLocation
			},
		},
	},
	methods: {
		handleInputEnter(event) {
			this.$store.dispatch('SearchSubsidiary', event.target.value)
		},
		handleInputClick(event) {
		 this.$store.dispatch('SearchSubsidiary', event.target.value)
	},
	},
}
</script>

<style lang="scss">
.jobs-location-results {
	.jobs-location-results-header-light {
		text-transform: none;
		color: color(primary, light);
	}
}
</style>
