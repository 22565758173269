<template>
	<div class="fp-section highlight-box--image fullwidth-container">
		<div class="jobfinder-container fullwidth-container--background-image--container">
			<img v-if="image"
				:src="image[0].publicUrl"
				:alt="image[0].properties.alternative"
				class="image--cover"
			/>
		</div>
		<div class="fullwidth-container">
			<div class="page--wrapper">
				<div class="container">
					<div class="multi-columns">
						<div class="row">
							<div class="col-xs-12 col-lg-6 col-md-8">
								<div class="layout-default">
									<div class="ce-text">
							 			<h3 v-html="header"></h3>
										<html-parser :content="bodytext"/>
									</div>
								</div>
								<div class="row gy-5 gx-5">
									<div
										class="col-md-6"
										v-for="(facet, index) in checkbox_facets"
										:key="index.value"
									>

									<WorkspaceFacet v-bind:facet="facet" />

									</div>
									<div class="col-md-6">
										<SearchInput />
									</div>
								</div>
								<div class="row gy-5 gx-5">
									<div class="col">
										<nuxt-link
											to="/bewerber/jobsuche/jobboerse"
											class="mt-4 text-center btn btn-pill btn-secondary w-100"
											title="Zeig mir meine neuen Jobs"
										>
											<span class="text-center">
												Zeig mir meine neuen Jobs
											</span>
										</nuxt-link>

									</div>
								</div>
							</div>
							<div class="col-xs-12 col-lg-6 col-md-4">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState } from 'vuex';
import SearchInput from '../jobmarket/inputs/SearchInput';
import SearchResults from '../jobmarket/results/SearchResults';
import CeHeaderBase from '~typo3/components/content/elements/CeHeader.vue';
import CeText from '~typo3/components/content/elements/CeText.vue';
import WorkspaceFacet from '~/components/organisms/jobmarket/dropdowns/WorkspaceFacet';

export default {
	name: 'Jobfinder',
	extends: CeHeaderBase, CeText,
	components: {
		SearchInput,
		SearchResults,
		WorkspaceFacet
	},
	props: {
		facet: {
			type: Object
		},
		bodytext: {
			type: String
		},
		image: {
			type: Array
		},
	},
	computed: {
		...mapState([
			'results',
			'checkbox_facets',
			'dropdown_facets',
			'filtersActive',
		]),
		filters: {
			get() {
				return this.$store.state.filters;
			},
			set(value) {
				this.$store.dispatch('setFilters', value);
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/common';
	.jobfinder-container {
		&.fullwidth-container--background-image--container {
			@include media-breakpoint-down(md) {
				position: relative;
				margin-bottom: rem(50);

				.image--cover {
					min-height: 280px;
				}
			}
		}
	}
</style>
