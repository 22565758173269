var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 jobs-location-results-list"},[(_vm.loadingStatus)?_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading"}),_vm._v("\n\t\tDaten werden geladen...\n\t")]):_vm._e(),_vm._v(" "),(_vm.results.length > 0)?_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.results),function(item,i){return _c('li',{key:`${i}-${item.stellenangebotId}`},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'arrow-circle-right']}}),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
						name: 'job-finden-job-stellenangebotId-jobTitel',
						params: {
							stellenangebotId: item.stellenangebotId,
							jobTitel: item.jobTitel
								.replace(/\s+|[,\/]/g, '-')
								.toLowerCase(),
						},
					})}},[_vm._v("\n\t\t\t\t"+_vm._s(item.jobTitel)+"\n\t\t\t")])],1)}),0):_c('div',[_vm._v("\n\t\tZur Zeit sind keine Jobs für verfügbar.\n\t")])])
}
var staticRenderFns = []

export { render, staticRenderFns }