<template>
	<div class="search-results-wrapper">
		<results-sorting class="mb-3 results-top"></results-sorting>
		<div class="result-table-wrapper">
			<div class="table-headers">
				<div
					class="d-flex justify-content-between align-items-center w-100"
				>
					<header class="h3 mt-4 mb-3">
						<span> {{ filteredResultsCount }} Jobs gefunden </span>
					</header>

					<div class="action-cell flex-basis-5">
						<nuxt-link
							to="/bewerber/jobsuche/jobboerse/jobboerse-merkliste"
							class="btn action"
						>
							<font-awesome-icon
								:icon="['fal', 'clipboard-list']"
							/>
							<span class="button-text">
								{{ Button }}
							</span>
						</nuxt-link>
					</div>
				</div>
			</div>
		</div>
		<div v-if="loadingStatus" class="loading-container">
			<div class="loading"></div>
			Daten werden geladen...
		</div>

		<ResultItem />

		<results-sorting class="mt-4 results-bottom"></results-sorting>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import ResultItem from './ResultItem.vue'
import ResultsSorting from './ResultsSorting.vue'

export default {
	data() {
		return {
			TableHeaderJobTitle: 'Jobbezeichnung',
			TableHeaderWorkLocation: 'Arbeitsort',
			TableHeaderPayment: 'Vergütung',
			Button: 'Merkliste',
		}
	},
	components: {
		ResultItem,
		ResultsSorting,
	},
	computed: {
		...mapState(['results', 'datajobs', 'resultsCount']),
		filteredResults() {
			const addressMap = {}

			this.results.forEach((item) => {
				const addressKey =
					item.jobOrte[0].jobPlz + '-' + item.jobOrte[0].jobStrasse
				if (!addressMap[addressKey]) {
					addressMap[addressKey] = {
						hasEmptyReferenzNummer: false,
						hasNonEmptyReferenzNummer: false,
					}
				}
				if (item.referenzNummer === null) {
					addressMap[addressKey].hasEmptyReferenzNummer = true
				} else {
					addressMap[addressKey].hasNonEmptyReferenzNummer = true
				}
			})

			return this.results.filter((item) => {
				const addressKey =
					item.jobOrte[0].jobPlz + '-' + item.jobOrte[0].jobStrasse
				const info = addressMap[addressKey]

				return !(
					info.hasEmptyReferenzNummer &&
					info.hasNonEmptyReferenzNummer &&
					item.referenzNummer === null
				)
			})
		},
		filteredResultsCount() {
        return this.resultsCount;
   		},
		loadingStatus() {
			return this.$store.getters.loadingStatus
		},
	},

	watch: {
		filteredResultsCount(newCount) {
			this.$store.commit('setResultsCount', newCount)
		},
	},
}
</script>
<style lang="scss">
@import '~@/assets/scss/common';
.search-results-wrapper {
	position: relative;

	.loading-container {
		width: 100%;
		height: 100%;
		background: $white;
		position: absolute;
		left: 0;
		right: 0;
		z-index: 4;
	}
	.pagination {
		gap: 10px;
	}
	.page-item .page-link {
		@include media-breakpoint-up(md) {
			font-size: rem(15);
		}
		border-radius: 50rem;
	}
}
</style>
