<template>
	<div class="result-table-item">
		<div
			class="result-table-wrapper"
			v-for="(item, i) in filteredResults"
			:key="`${i}-${item.stellenangebotId}`"
		>
			<div class="result-table-body">
				<!--Row Starts-->
				<div class="result-table-row">
					<!--Text Job title -->
					<div class="result-table-cell flex-basis-50">
						<div class="center-wrapper">
							<nuxt-link
								:to="
									localePath({
										name: 'job-finden-job-stellenangebotId-jobTitel',
										params: {
											stellenangebotId:
												item.stellenangebotId,
											jobTitel: item.jobTitel
												.replace(/\s+|[,\/]/g, '-')
												.toLowerCase(),
										},
									})
								"
								class="result-table-item-header"
							>
								{{ item.jobTitel }}
							</nuxt-link>

							<media :query="{ maxWidth: 680 }">
								<p
									v-for="(item, jobOrte) in item.jobOrte"
									:key="jobOrte"
								>
									{{ item.jobOrt }}
									({{ item.jobRegion }})
								</p>
							</media>
						</div>
					</div>

					<media :query="{ minWidth: 770 }">
						<!--Text jobOrte -->
						<div class="result-table-cell flex-basis-50">
							<div class="center-wrapper">
								<p
									v-for="(item, jobOrte) in item.jobOrte"
									:key="jobOrte"
								>
									{{ item.jobOrt }}
									({{ item.jobRegion }})
								</p>
							</div>
						</div>
					</media>
					<!--Button action Bookmark -->
					<div class="result-table-cell flex-basis-5 action-cell">
						<button
							class="btn action"
							:class="
								isBookmark(item.id)
									? 'bookmarks on'
									: 'bookmarks'
							"
							@click="
								toggleBookmark(
									$event,
									item.jobTitel,
									item.jobOrt,
									item.jobRegion,
									item.jobOrte,
									item.stellenangebotId
								)
							"
						>
							<i class="icon-bookmarks"></i>
							<span class="button-text"></span>
						</button>
					</div>
				</div>
				<!--Row Ends-->
			</div>
		</div>

		<!-- <pre>{{results}}</pre> -->
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Media from 'vue-media'
import BookmarkItem from '../bookmarks/BookmarkItem'
import JobsListMixins from '../mixins/JobsListMixins'
export default {
	components: {
		Media,
		BookmarkItem,
	},
	mixins: [JobsListMixins],
	props: ['item'],
	data() {
		return {
			stellenangebotId: this.$route.params.stellenangebotId,
			tempbookmark: [],
			Agreement: 'nach Vereinbarung',
			Button: 'Merken',
		}
	},
	computed: {
		...mapState(['results', 'datajobs']),

		filteredResults() {
			const addressMap = {}

			this.results.forEach((item) => {
				const addressKey =
					item.jobOrte[0].jobPlz + '-' + item.jobOrte[0].jobStrasse
				if (!addressMap[addressKey]) {
					addressMap[addressKey] = {
						hasEmptyReferenzNummer: false,
						hasNonEmptyReferenzNummer: false,
					}
				}
				if (item.referenzNummer === null) {
					addressMap[addressKey].hasEmptyReferenzNummer = true
				} else {
					addressMap[addressKey].hasNonEmptyReferenzNummer = true
				}
			})

			return this.results.filter((item) => {
				const addressKey =
					item.jobOrte[0].jobPlz + '-' + item.jobOrte[0].jobStrasse
				const info = addressMap[addressKey]

				return !(
					info.hasEmptyReferenzNummer &&
					info.hasNonEmptyReferenzNummer &&
					item.referenzNummer === null
				)
			})
		},

		rows() {
			return this.results.length
		},
		results: {
			get() {
				return this.$store.state.results
			},
		},
	},
	mounted() {
		this.$store.dispatch('createDataJobs')
		console.log(this.results)
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$result-table-border: 1px solid #ddd !default;
$bookmarks-icon: plus-circle !default;
$bookmarks-icon-bookmarkmarket: check-circle !default;

.table-headers {
	display: flex;
	border-bottom: $result-table-border;
}

.result-table-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: 800;
}

.result-table-wrapper {
	margin-top: 0;
}

.result-table-row {
	display: flex;
	padding: 5px 0;

	@include media-breakpoint-up(md) {
		padding-bottom: 0;
	}
}

.result-table-item-header {
	@include responsive-type(3, 1);
	color: $primary-light;
	font-weight: $font-weight-medium;

	@include media-breakpoint-up(md) {
		@include responsive-type(2, 1);
	}
}

.result-table-body {
	margin-top: 0;
}

.result-table-cell {
	padding-bottom: 5px;

	@include media-breakpoint-up(md) {
		padding: 0;
	}

	display: flex;
	border-bottom: $result-table-border;
}

.search-results-wrapper {
	.result-table-cell {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.center-wrapper {
	width: 90%;

	@include media-breakpoint-up(md) {
		width: 100%;
	}

	p {
		margin-bottom: 0;
		padding: 0 15px 0 0;
	}
}

.action-cell {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	button,
	a {
		padding: 6px 5px;
		width: 100%;
		color: $primary;
		font-size: 14px;
		box-shadow: none;
		border: 0;
		&:active,
		&.action:active svg {
			box-shadow: none;
			color: $secondary;
			border: 0;
		}

		&:hover,
		&:focus {
			border: 0;
		}

		@include media-breakpoint-up(md) {
			padding: 6px 14px;
		}

		&.action svg {
			font-size: rem(20);
			color: $tertiary;
			font-weight: $font-weight-medium;
		}
	}
}

.action-cell .bookmarks {
	@include media-breakpoint-up(md) {
		height: 60px;
		width: 80px;
	}

	.icon-bookmarks:before {
		color: $tertiary;
		font-size: rem(20);
		content: fa-content($fa-var-plus-circle);
		font-family: 'Font Awesome 5 Pro';
		@extend %fa-icon;
	}

	.button-text:after {
		content: 'Merken';
	}

	&.on {
		.button-text:after {
			content: 'Gemerkt';
		}

		.icon-bookmarks::before {
			content: '';
			background: url('data:image/svg+xml,#{svg-circle-solid($secondary)}')
				center center no-repeat;
			width: 20px;
			height: 20px;
			line-height: 1;
			margin: 0;
		}
	}
}

@include media-breakpoint-down(lg) {
	.tab-header {
		display: inherit;
	}
}

@include media-breakpoint-down(lg) {
	.result-table-cell.flex-basis-50 {
		@include flex-basis-100;
	}
}
</style>
